(function ($, generic) {
  var titleTemplate;
  var titleVars = {};
  var $titles = $();
  var titleClass = 'signin-block__authed__nav__title';
  var rClass = titleClass + '--rendered';

  function _renderTitles() {
    if (titleTemplate) {
      $titles.html(Mustache.render(titleTemplate, titleVars)).addClass(rClass);
    }
  }

  Drupal.behaviors.signinBlock = {
    attach: function (context) {
      var $blocks = $('.js-signin-block', context);

      $titles = $titles.add($('.' + titleClass, $blocks));
      var firstName = site.userInfoCookie.getValue('first_name');

      if (firstName) {
        titleVars.first_name = firstName;
      }
      if (!titleTemplate) {
        titleTemplate = $titles.first().html();
      }
      _renderTitles();

      $blocks.each(function () {
        var $block = $(this);
        var $toggleTrigger = $('.js-signin-block-toggle', this);
        var $registerForm = $('.signin-block__form--register', this);
        var $toggleRegisterPass = $('.signin-block__toggle-password__option', $registerForm);
        var $registerPass = $('.signin-block__field--password', $registerForm);
        var $forgotPasswordLink = $('.js-forgot-password', this);
        var showPass = false;
        var $signinEmailAddressNode = $block.find('.js-signin-block-email', context);
        var $passwordResetEmailNode = $('.js-password_reset_send__overlay_EMAIL_ADDRESS', context);
        var $errors = $('#form--errors--signin', context);
        var $passwordResetForm = $('.js-sign-in-page__overlay_password_reset_send form', context);
        var rb = generic.rb('error_messages');
        var errorMsgEmailAddress = rb.get('required.email_address');
        var errorMsg = rb.get('signin_error.email_address');
        var Hash = generic.Hash();
        var param;
        var emailAddress;

        function togglePassword() {
          showPass = !showPass;
          $registerPass.get(0).type = showPass ? 'text' : 'password';
          $block.toggleClass('signin-block--show-password', showPass);
        }

        $toggleRegisterPass.on('click', function (event) {
          event.preventDefault();
          togglePassword();
        });

        $registerForm.on('submit', function () {
          if (showPass) {
            togglePassword();
          }
        });

        $toggleTrigger.on('click', function (event) {
          event.preventDefault();
          $block.toggleClass('signin-block--signin');
        });

        if ($passwordResetEmailNode.length === 1) {
          $signinEmailAddressNode.once().on('change', function () {
            $passwordResetEmailNode.val($(this).val());
          });
        }

        $forgotPasswordLink.on('click', function (e) {
          e.preventDefault();
          emailAddress = $signinEmailAddressNode.val();
          $errors.empty();
          if (emailAddress.length < 1) {
            $errors.append(
              "<li id='signin_error.email_address.'>" + errorMsgEmailAddress + '</li>'
            );

            return;
          }
          if ($passwordResetEmailNode.length === 1) {
            $passwordResetEmailNode.val(emailAddress);
            param = Hash.queryToJson($passwordResetForm.serialize());
            Object.entries(param).forEach(([key, value]) => {
              param[key] = decodeURIComponent(value);
            });
            generic.jsonrpc.fetch({
              method: 'rpc.form',
              params: [param],
              onBoth: function (r) {
                var messages = r.getMessages();

                if (messages) {
                  messages.forEach(function (message) {
                    if (message.key === 'request_sent') {
                      $errors.append("<li id='passwordResetSendMsg'>" + errorMsg + '</li>');
                    } else {
                      $errors.append(
                        "<li id='signin_error.email_address.'>" + rb.get(message.key) + '</li>'
                      );
                    }
                  });
                }
              }
            });
          } else if (emailAddress !== '') {
            site.signin.requestPassword(emailAddress);
          }
        });
      });

      if (generic.cookie('USER_RECOGNIZED')) {
        $blocks.addClass('signin-block--signin');
      }
    }
  };

  $(document).on('user.loaded', function (event, val) {
    // This is brittle but there's no other way to tell from the data we get.
    if (!$titles.length) {
      $titles = $('.' + titleClass);
    }
    if (!titleTemplate) {
      titleTemplate = $titles.first().html();
    }
    titleVars.first_name = val.first_name;

    // If user is ever signed in, permanently store a "USER_REC" cookie. This
    // only expires once a year.
    if (val.signed_in) {
      generic.cookie('USER_RECOGNIZED', 1, {
        path: '/',
        expires: 365
      });
    }

    _renderTitles();
  });
})(jQuery, window.generic || {});
